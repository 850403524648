var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6 pb-8 pt-5 pt-md-8",attrs:{"type":"gradient-yellow"}}),_c('b-container',{staticClass:"mt--7",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('card',[_c('datatable',{ref:"datatable",attrs:{"classe":"table table-bordered table-striped","url":_vm.datatableUrl,"btnExport":false},on:{"create":function($event){return _vm.$router.push({ name: 'categories-create' })}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var actions = ref.actions;
return _c('div',{},[_c('b-dropdown',{attrs:{"boundary":"viewport","size":"sm","text":"Actions","variant":"primary"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({
                      name: 'categories-edit',
                      params: { id: actions.id },
                    })}}},[_c('i',{staticClass:"fa fa-edit p-1"}),_vm._v(" Edit ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.changeStatus(actions.status === 'E' ? 'D' : 'E', actions.id)}}},[_c('i',{staticClass:"fa fa-toggle-on p-1"}),_vm._v(" Change Status ")])],1)],1)}},{key:"status",fn:function(ref){
                    var data = ref.data;
return [_c('small',{class:'badge badge-' + (data == 'E' ? 'primary' : 'danger')},[_vm._v(" "+_vm._s(data == "E" ? "Enabled" : "Disabled")+" ")])]}},{key:"image",fn:function(ref){
                    var data = ref.data;
return [_c('img',{staticClass:"rounded",attrs:{"src":_vm.resource(data),"width":"70","alt":data}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }